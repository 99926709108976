<template>
  <b-card>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"
    />
    <edit-field2
      :fields="fields"
      :row-data="row_data"
      post-api="/callbell/AddCallBell"
      finish-router="callBellEnvironment"
      permission="CallBell_Menu_Environment"
    >
    </edit-field2>
  </b-card>
</template>

<script>
import fields from "./fields";
import EditField2 from "@/components/EditField2";
import EditFieldHeader from "@/components/EditFieldHeader";
import common from "@/common";


export default {
  name: "Add",
  components: {
    EditFieldHeader,
    EditField2,
    fields
  },
  data() {
    return {
      title: common.getMenuName('callBellEnvironment') + ' - ' + common.getI18n('common.add'),
      fields,
      // 如果是checkbox，里面写 field: [], 如ids: []
      row_data: {}
    }
  }
}
</script>

<style scoped>

</style>
