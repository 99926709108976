import common from "@/common";

export default [
  {
    name: 'deviceMAC',
    label: 'device_env.mac',
    type: 'text',
    rule: 'required|length:16',
    edit_disable: true,
  },
  {
    name: 'deviceName',
    label: 'call_bell.call_bell_name',
    type: 'text',
    rule: 'required|max:20',
  },
  {
    name: 'isUAT',
    label: 'device_env.env',
    type: 'select',
    rule: 'required',
    options: [
      {text: common.getI18n('device_env.env_uat'), value: true},
      {text: common.getI18n('device_env.env_production'), value: false},
    ],
  }



]
